import { Router } from '@reach/router'
import Photos from '../../components/Photos'
import Gallery from '../../components/Gallery'

const Fotky = () => {
  return (
    <Router>
      <Gallery {...({ path: '/fotky/galerie/:id' } as PagePropsPagination)} />
      <Photos {...({ path: '/fotky/:page' } as PagePropsPagination)} />
      <Photos {...({ path: '/fotky/' } as PagePropsPagination)} />
    </Router>
  )
}
export default Fotky
