import { useState } from 'react'
import Screen from './Screen'
import Pagination from './Pagination'
import GalleryLink from './GalleryLink'
import Gallery, { PhotoClickHandler } from 'react-photo-gallery-react17'
import Carousel, { Modal, ModalGateway } from 'react-images'
import useGalleries from '../hooks/useGalleries'
import cn from 'classnames'

const Photos = ({ uri, page = '1', ...rest }: PagePropsPagination) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [currentGallery, setCurrentGallery] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const parsedPage = parseInt(page)

  const { galleries, numberOfPages } = useGalleries(parsedPage, false)
  const activeGallery = galleries[currentGallery]

  const openLightbox =
    (galleryIndex: number): PhotoClickHandler =>
    (_, { index }) => {
      setCurrentImage(index)
      setCurrentGallery(galleryIndex)
      setViewerIsOpen(true)
    }

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Screen uri={uri} {...rest}>
      <h2 className="h2-medium divider pb-4 mb-4">Fotky</h2>
      {galleries.map(({ title, photos, id }, index) => (
        <div
          className={cn({ 'divider pb-6': index !== galleries.length - 1 })}
          key={title}
        >
          <GalleryLink title={title} id={id} />
          <Gallery photos={photos} onClick={openLightbox(index)} />
        </div>
      ))}
      <Pagination
        numberOfPages={numberOfPages}
        page={parsedPage}
        type="photos"
      />
      <ModalGateway>
        {viewerIsOpen && activeGallery && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={activeGallery.photos.map((photo) => ({
                source: {
                  regular: photo.src,
                },
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </Screen>
  )
}

export default Photos
